import React from 'react'

export default function Transactions() {
  return (
    <div className="col-xxl-3">
    <div className="card h-100">
      <div className="card-body">
        <div className="d-flex align-items-center flex-wrap gap-2 justify-content-between">
          <h6 className="mb-2 fw-bold text-lg">Transactions</h6>
          <div>
            <select className="form-select form-select-sm w-auto bg-base border text-secondary-light">
              <option>This Month</option>
              <option>Last Month</option>
            </select>
          </div>
        </div>
  
        <div className="mt-32">
          {[
            { date: '25-05-24', amount: '-$20', type: 'text-danger' },
            { date: '25-05-24', amount: '+$800', type: 'text-success-main' },
            { date: '25-05-24', amount: '-$300', type: 'text-danger-main' },
            { date: '25-05-24', amount: '+$500', type: 'text-success-main' },
            { date: '25-05-24', amount: '-$1500', type: 'text-danger-main' },
            { date: '25-05-24', amount: '+$800', type: 'text-success-main' },
          ].map((transaction, index) => (
            <div key={index} className="d-flex align-items-center justify-content-between gap-3 mb-32">
              <div className="d-flex align-items-center gap-2">
                <div className="flex-grow-1">
                  <h6 className="text-md mb-0 fw-normal">{transaction.date}</h6>
                </div>
              </div>
              <span className={`${transaction.type} text-md fw-medium`}>{transaction.amount}</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  </div>
  
  )
}
