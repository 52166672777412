// import logo from './logo.svg';
import './App.css';
import SideMenu from './common/SideMenu';
import { RouterProvider } from 'react-router-dom';
import AppRoutes from './routes';

function App() {
  const router = AppRoutes()
  return (
    <RouterProvider router={router} />
  );
}

export default App;
