import { Icon } from '@iconify/react'
import React from 'react'
import RevenueReport from './RevenueReport'
import Tiles from './Tiles'
import StudentStatistics from './StudentStatistics'
import RecentPurchases from './RecentPurchases'
import Transactions from './Transactions'
import TopTutors from './TopTutors'
import TopStudents from './TopStudents'

export default function MainDashboard() {
    return (
        <div class="dashboard-main-body">
            <div class="row gy-4">

                <div className="col-xxl-9">
                    <div className="card radius-8 border-0">
                        {/* <span></span> */}
                        <div className="row">
                            <RevenueReport />
                            <Tiles />
                        </div>
                    </div>
                </div>
                <StudentStatistics />
                <RecentPurchases />
                <Transactions />
                <TopTutors />
                <TopStudents />
            </div>
        </div>

    )
}
