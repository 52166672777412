import { Icon } from '@iconify/react'
import React from 'react'

export default function Tiles() {
  return (
    <div className="col-xxl-6">
    <div className="row h-100 g-0">
        <div className="col-6 p-0 m-0">
            <div className="card-body p-24 h-100 d-flex flex-column justify-content-center border border-top-0">
                <div className="d-flex flex-wrap align-items-center justify-content-between gap-1 mb-8">
                    <div>
                        <span className="mb-12 w-44-px h-44-px text-primary-600 bg-primary-light border border-primary-light-white flex-shrink-0 d-flex justify-content-center align-items-center radius-8 h6 mb-12">
                            <Icon icon="fa-solid:box-open" className="icon"></Icon>
                        </span>
                        <span className="mb-1 fw-medium text-secondary-light text-md">Total Courses</span>
                        <h6 className="fw-semibold text-primary-light mb-1">300</h6>
                    </div>
                </div>
                <p className="text-sm mb-0">
                    Increase by{" "}
                    <span className="bg-success-focus px-1 rounded-2 fw-medium text-success-main text-sm">+200</span> this week
                </p>
            </div>
        </div>
        <div className="col-6 p-0 m-0">
            <div className="card-body p-24 h-100 d-flex flex-column justify-content-center border border-top-0 border-start-0 border-end-0">
                <div className="d-flex flex-wrap align-items-center justify-content-between gap-1 mb-8">
                    <div>
                        <span className="mb-12 w-44-px h-44-px text-yellow bg-yellow-light border border-yellow-light-white flex-shrink-0 d-flex justify-content-center align-items-center radius-8 h6 mb-12">
                            <Icon icon="flowbite:users-group-solid" className="icon"></Icon>
                        </span>
                        <span className="mb-1 fw-medium text-secondary-light text-md">Total Tutors</span>
                        <h6 className="fw-semibold text-primary-light mb-1">50,000</h6>
                    </div>
                </div>
                <p className="text-sm mb-0">
                    Increase by{" "}
                    <span className="bg-danger-focus px-1 rounded-2 fw-medium text-danger-main text-sm">-5k</span> this week
                </p>
            </div>
        </div>
        <div className="col-6 p-0 m-0">
            <div className="card-body p-24 h-100 d-flex flex-column justify-content-center border border-top-0 border-bottom-0">
                <div className="d-flex flex-wrap align-items-center justify-content-between gap-1 mb-8">
                    <div>
                        <span className="mb-12 w-44-px h-44-px text-lilac bg-lilac-light border border-lilac-light-white flex-shrink-0 d-flex justify-content-center align-items-center radius-8 h6 mb-12">
                            <Icon icon="majesticons:shopping-cart" className="icon"></Icon>
                        </span>
                        <span className="mb-1 fw-medium text-secondary-light text-md">Total Students</span>
                        <h6 className="fw-semibold text-primary-light mb-1">1500</h6>
                    </div>
                </div>
                <p className="text-sm mb-0">
                    Increase by{" "}
                    <span className="bg-success-focus px-1 rounded-2 fw-medium text-success-main text-sm">+1k</span> this week
                </p>
            </div>
        </div>
        <div className="col-6 p-0 m-0">
            <div className="card-body p-24 h-100 d-flex flex-column justify-content-center border border-top-0 border-start-0 border-end-0 border-bottom-0">
                <div className="d-flex flex-wrap align-items-center justify-content-between gap-1 mb-8">
                    <div>
                        <span className="mb-12 w-44-px h-44-px text-pink bg-pink-light border border-pink-light-white flex-shrink-0 d-flex justify-content-center align-items-center radius-8 h6 mb-12">
                            <Icon icon="ri:discount-percent-fill" className="icon"></Icon>
                        </span>
                        <span className="mb-1 fw-medium text-secondary-light text-md">Total Sales</span>
                        <h6 className="fw-semibold text-primary-light mb-1">$25,000,000.00</h6>
                    </div>
                </div>
                <p className="text-sm mb-0">
                    Increase by{" "}
                    <span className="bg-success-focus px-1 rounded-2 fw-medium text-success-main text-sm">+$10k</span> this week
                </p>
            </div>
        </div>
    </div>
</div>
  )
}
