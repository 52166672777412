import React from 'react'

function ListRankedTutors() {
    return (
        <div className="admin_content_row">
            <h6>Rank Wise Tutors</h6>
            <div className="users_list_section">
                <div className="users_list_row">
                    <div className="users_list_box">
                        <div className="users_list_box_img"></div>
                        <div className="users_list_box_cnt">
                            <h6>Tutor Name</h6>
                            <p>Course Name</p>
                            <h6>Rank: 250</h6>
                        </div>
                    </div>
                    <div className="users_list_box">
                        <div className="users_list_box_img"></div>
                        <div className="users_list_box_cnt">
                            <h6>Tutor Name</h6>
                            <p>Course Name</p>
                            <h6>Rank: 250</h6>
                        </div>
                    </div>
                    <div className="users_list_box">
                        <div className="users_list_box_img"></div>
                        <div className="users_list_box_cnt">
                            <h6>Tutor Name</h6>
                            <p>Course Name</p>
                            <h6>Rank: 250</h6>
                        </div>
                    </div>
                    <div className="users_list_box">
                        <div className="users_list_box_img"></div>
                        <div className="users_list_box_cnt">
                            <h6>Tutor Name</h6>
                            <p>Course Name</p>
                            <h6>Rank: 250</h6>
                        </div>
                    </div>
                    <div className="users_list_box">
                        <div className="users_list_box_img"></div>
                        <div className="users_list_box_cnt">
                            <h6>Tutor Name</h6>
                            <p>Course Name</p>
                            <h6>Rank: 250</h6>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default ListRankedTutors