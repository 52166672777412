import React, { useState } from 'react'
import CourseDetails from '../../components/courses/CourseDetails'
import CourseModules from '../../components/courses/CourseModules'
import AddSubject from '../../components/courses/AddSubject'
import Addmodule from '../../components/courses/Addmodule'
import VideoPreview from '../../components/courses/VideoPreview'
import AddmoduleModal from '../../components/courses/AddmoduleModal'
import AddSubjectModal from '../../components/courses/AddSubjectModal'
function Module() {
    const [showVideoPreviewModal, setshowVideoPreviewModal] = useState(false)
    const [showAddModuleModal, setshowAddModuleModal] = useState(false)
    const [showAddSubjectModal, setshowAddSubjectModal] = useState(false)
    const handleCloseVidPreview = () => {
        setshowVideoPreviewModal(false)
    }
    const handleCloseAddmoduleModal = () => {
        setshowAddModuleModal(false)
    }
    const handleCloseAddsubjectModal = () => {
        setshowAddSubjectModal(false)
    }
    return (
        <>
            <div className="dashboard-main-body">
                <CourseDetails setshowAddSubjectModal={setshowAddSubjectModal}/>
                <CourseModules setshowVideoPreviewModal={setshowVideoPreviewModal} />
                <AddSubject setshowAddSubjectModal={setshowAddSubjectModal}/>
                <Addmodule setshowAddModuleModal={setshowAddModuleModal} />
            </div>
            <VideoPreview
                show={showVideoPreviewModal}
                handleClose={handleCloseVidPreview}
            />
            <AddmoduleModal
                show={showAddModuleModal}
                handleClose={handleCloseAddmoduleModal}
            />
            <AddSubjectModal
                show={showAddSubjectModal}
                handleClose={handleCloseAddsubjectModal}
            />
        </>

    )
}

export default Module