import React from 'react'
import { Icon } from '@iconify/react'
function CourseModules({ setshowVideoPreviewModal }) {
    return (
        <div className="card mt-4">
            <div className="card-body">
                <div className="table-responsive">
                    <table className="table table-style">
                        <thead>
                            <tr>
                                <th>
                                    <span className="d-block text-sm fw-normal fs-6">Module Name</span>
                                    <input
                                        type="text"
                                        className="moduleSecInput"
                                        id="floatingInput"
                                        defaultValue="Learn Python"
                                    />
                                </th>
                                <th>
                                    <div className="float-end d-flex justify-content-end">
                                        <div>
                                            <span className="d-inline-block text-sm fw-normal fs-6">Total Time</span>
                                            <div className="icon-field">
                                                <Icon className="icon" icon="carbon:time"></Icon>
                                                <input
                                                    type="text"
                                                    className="moduleSecInput"
                                                    defaultValue="24 Hour"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </th>
                            </tr>
                        </thead>
                    </table>
                </div>

                <div className="table-responsive">
                    <table className="table table-style">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Subject Name</th>
                                <th>Duration</th>
                                <th>URL</th>
                                <th>Preview</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody id="tbody-add-row">
                            <tr>
                                <td>1</td>
                                <td>
                                    <input
                                        type="text"
                                        className="moduleSecInput"
                                        defaultValue="Python Introduction"
                                    />
                                </td>
                                <td>
                                    <input
                                        type="text"
                                        className="moduleSecInput"
                                        defaultValue="8:58:29"
                                    />
                                </td>
                                <td>
                                    <input
                                        type="text"
                                        className="moduleSecInput"
                                        defaultValue="#"
                                    />
                                </td>
                                <td>
                                    <button
                                        type="button"
                                        // data-bs-target="#viewVideo"
                                        // data-bs-toggle="modal"
                                        onClick={() => setshowVideoPreviewModal(true)}
                                        className="bg-info-focus bg-hover-info-200 text-info-600 fw-medium w-40-px h-40-px d-flex justify-content-center align-items-center rounded-circle"
                                    >
                                        <Icon icon="ph:video" className="icon text-xl"></Icon>
                                    </button>
                                </td>
                                <td>
                                    <div className="d-flex">
                                        <button className="btn btn-danger action_btn_edit_delete remove">
                                            <Icon icon="ic:baseline-delete" className="icon text-lg"></Icon>
                                        </button>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>2</td>
                                <td>
                                    <input
                                        type="text"
                                        className="moduleSecInput"
                                        defaultValue="Python Variables"
                                    />
                                </td>
                                <td>
                                    <input
                                        type="text"
                                        className="moduleSecInput"
                                        defaultValue="8:58:29"
                                    />
                                </td>
                                <td>
                                    <input
                                        type="text"
                                        className="moduleSecInput"
                                        defaultValue="#"
                                    />
                                </td>
                                <td>
                                    <button
                                        type="button"
                                        data-bs-target="#viewVideo"
                                        data-bs-toggle="modal"
                                        className="bg-info-focus bg-hover-info-200 text-info-600 fw-medium w-40-px h-40-px d-flex justify-content-center align-items-center rounded-circle"
                                    >
                                        <Icon icon="ph:video" className="icon text-xl"></Icon>
                                    </button>
                                </td>
                                <td>
                                    <div className="d-flex">
                                        <button className="btn btn-danger action_btn_edit_delete remove">
                                            <Icon icon="ic:baseline-delete" className="icon text-lg"></Icon>
                                        </button>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>3</td>
                                <td>
                                    <input
                                        type="text"
                                        className="moduleSecInput"
                                        defaultValue="Python Array"
                                    />
                                </td>
                                <td>
                                    <input
                                        type="text"
                                        className="moduleSecInput"
                                        defaultValue="8:58:29"
                                    />
                                </td>
                                <td>
                                    <input
                                        type="text"
                                        className="moduleSecInput"
                                        defaultValue="#"
                                    />
                                </td>
                                <td>
                                    <button
                                        type="button"
                                        data-bs-target="#viewVideo"
                                        data-bs-toggle="modal"
                                        className="bg-info-focus bg-hover-info-200 text-info-600 fw-medium w-40-px h-40-px d-flex justify-content-center align-items-center rounded-circle"
                                    >
                                        <Icon icon="ph:video" className="icon text-xl"></Icon>
                                    </button>
                                </td>
                                <td>
                                    <div className="d-flex">
                                        <button className="btn btn-danger action_btn_edit_delete remove">
                                            <Icon icon="ic:baseline-delete" className="icon text-lg"></Icon>
                                        </button>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}

export default CourseModules