import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';

function AddmoduleModal({show,handleClose}) {
    return (
        <Modal
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
            centered
            size="lg"
        >
            <Modal.Header closeButton>
                <Modal.Title>Add Module</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form className="add_course_popup">
                    <div className="user-details">
                        <Form.Group className="input-box" controlId="formModuleName">
                            <Form.Label className="details">Module Name</Form.Label>
                            <Form.Control type="text" placeholder="Module Name" />
                        </Form.Group>
                        <Form.Group className="input-box" controlId="formDuration">
                            <Form.Label className="details">Duration</Form.Label>
                            <Form.Control type="text" placeholder="Duration" />
                        </Form.Group>
                    </div>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
                <Button variant="primary" id="add_module_btn">
                    Add Module
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default AddmoduleModal