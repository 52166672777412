import React from 'react'

function CourseDetails() {
    return (
        <div className="card">
            <div className="card-body">
                <div className="row">
                    <div className="col-md-4">
                        <div className="form-floating mb-3">
                            <input
                                type="email"
                                className="form-control"
                                id="floatingInputCourseName"
                                value="Python Basics"
                                readOnly
                            />
                            <label htmlFor="floatingInputCourseName">Course Name</label>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="form-floating mb-3">
                            <input
                                type="email"
                                className="form-control"
                                id="floatingInputPrice"
                                value="$150"
                                readOnly
                            />
                            <label htmlFor="floatingInputPrice">Price</label>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="form-floating mb-3">
                            <input
                                type="email"
                                className="form-control"
                                id="floatingInputTotalHours"
                                value="150 hr"
                                readOnly
                            />
                            <label htmlFor="floatingInputTotalHours">Total Hours</label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CourseDetails