import React from 'react'
import { Modal, Form,Button } from 'react-bootstrap'
import { Icon } from '@iconify/react'


function AddCourseModal({show,handleClose}) {
    return (
        <Modal show={show} onHide={handleClose} size="lg" aria-labelledby="exampleModalLabel" centered>
            <Modal.Header closeButton>
                <Modal.Title id="exampleModalLabel">Add New</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <h6 className="text-md text-primary-light mb-16">Thumb Image</h6>
                {/* Upload Image Start */}
                <div className="mb-24 mt-16">
                    <div className="avatar-upload">
                        <div className="avatar-edit position-absolute bottom-0 end-0 me-24 mt-16 z-1 cursor-pointer">
                            <input type="file" id="imageUpload" accept=".png, .jpg, .jpeg" hidden />
                            <label
                                htmlFor="imageUpload"
                                className="w-32-px h-32-px d-flex justify-content-center align-items-center bg-primary-50 text-primary-600 border border-primary-600 bg-hover-primary-100 text-lg rounded-circle"
                            >
                                <Icon icon="solar:camera-outline" className="icon"></Icon>
                            </label>
                        </div>
                        <div className="avatar-preview">
                            <div id="imagePreview"></div>
                        </div>
                    </div>
                </div>
                {/* Upload Image End */}
                <Form>
                    <div className="row">
                        <div className="col-sm-6">
                            <Form.Group controlId="name" className="mb-20">
                                <Form.Label className="form-label fw-semibold text-primary-light text-sm mb-8">
                                    Course Name <span className="text-danger-600">*</span>
                                </Form.Label>
                                <Form.Control type="text" placeholder="Enter Full Name" className="radius-8" />
                            </Form.Group>
                        </div>
                        <div className="col-sm-6">
                            <Form.Group controlId="depart" className="mb-20">
                                <Form.Label className="form-label fw-semibold text-primary-light text-sm mb-8">
                                    Category <span className="text-danger-600">*</span>
                                </Form.Label>
                                <Form.Select className="radius-8 form-select">
                                    <option>Enter Title</option>
                                    <option>Enter Title One</option>
                                    <option>Enter Title Two</option>
                                </Form.Select>
                            </Form.Group>
                        </div>
                        <div className="col-sm-6">
                            <Form.Group controlId="Language" className="mb-20">
                                <Form.Label className="form-label fw-semibold text-primary-light text-sm mb-8">
                                    Language <span className="text-danger-600">*</span>
                                </Form.Label>
                                <Form.Select className="radius-8 form-select">
                                    <option>English</option>
                                    <option>Bangla</option>
                                    <option>Hindi</option>
                                    <option>Arabic</option>
                                </Form.Select>
                            </Form.Group>
                        </div>
                        <div className="col-sm-12">
                            <Form.Group controlId="desc" className="mb-20">
                                <Form.Label className="form-label fw-semibold text-primary-light text-sm mb-8">
                                    Description
                                </Form.Label>
                                <Form.Control as="textarea" placeholder="Write description..." className="radius-8" />
                            </Form.Group>
                        </div>
                    </div>
                    <div className="d-flex align-items-center justify-content-center gap-3">
                        <Button variant="outline-danger" onClick={handleClose} className="px-56 py-11 radius-8">
                            Cancel
                        </Button>
                        <Button variant="primary" type="button" className="px-56 py-12 radius-8">
                            Save
                        </Button>
                    </div>
                </Form>
            </Modal.Body>
        </Modal>
    )
}

export default AddCourseModal