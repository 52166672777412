import React from "react";
import Chart from "react-apexcharts";

export default function RevenueReport() {
    const options = {
        series: [{
            name: 'Net Profit',
            data: [20000, 16000, 14000, 25000, 45000, 18000, 28000, 11000, 26000, 48000, 18000, 22000]
        }, {
            name: 'Revenue',
            data: [15000, 18000, 19000, 20000, 35000, 20000, 18000, 13000, 18000, 38000, 14000, 16000]
        }],
        colors: ['#32b1d9', '#18145c'],
        labels: ['Active', 'New', 'Total'],
        legend: {
            show: false
        },
        chart: {
            type: 'bar',
            height: 250,
            toolbar: {
                show: false
            },
        },
        grid: {
            show: true,
            borderColor: '#D1D5DB',
            strokeDashArray: 4, // Use a number for dashed style
            position: 'back',
        },
        plotOptions: {
            bar: {
                borderRadius: 4,
                columnWidth: 10,
            },
        },
        dataLabels: {
            enabled: false
        },
        stroke: {
            show: true,
            width: 2,
            colors: ['transparent']
        },
        xaxis: {
            categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        },
        yaxis: {
            categories: ['0', '5000', '10,000', '20,000', '30,000', '50,000', '60,000', '60,000', '70,000', '80,000', '90,000', '100,000'],
        },
        fill: {
            opacity: 1,
            width: 18,
        },
    };

    return (
        <div className="col-xxl-6 pe-xxl-0">
            <div className="card-body p-24">
                <div className="d-flex align-items-center flex-wrap gap-2 justify-content-between">
                    <h6 className="mb-2 fw-bold text-lg">Revenue Report</h6>
                    <div>
                        <select className="form-select form-select-sm w-auto bg-base border text-secondary-light">
                            <option>Yearly</option>
                            <option>Monthly</option>
                            <option>Weekly</option>
                            <option>Today</option>
                        </select>
                    </div>
                </div>
                <ul className="d-flex flex-wrap align-items-center mt-3 gap-3">
                    <li className="d-flex align-items-center gap-2">
                        <span className="w-12-px h-12-px radius-2 bg-primary-600"></span>
                        <span className="text-secondary-light text-sm fw-semibold">
                            Earning: <span className="text-primary-light fw-bold">$500,000,000.00</span>
                        </span>
                    </li>
                    <li className="d-flex align-items-center gap-2">
                        <span className="w-12-px h-12-px radius-2 bg-yellow"></span>
                        <span className="text-secondary-light text-sm fw-semibold">
                            Expense: <span className="text-primary-light fw-bold">$20,000.00</span>
                        </span>
                    </li>
                </ul>
                <div className="mt-40">
                    <Chart options={options} series={options.series} type="bar" height={250} />
                </div>
            </div>
        </div>
    );
}
