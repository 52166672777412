import React, { useState } from 'react'
import ListRankedStudents from '../../components/student/ListRankedStudents'
import StudentListTable from '../../components/student/StudentListTable'
import AddstudentModal from '../../components/student/AddstudentModal';

function Student() {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    return (
        <>
            <div className='dashboard-main-body'>
                <ListRankedStudents />
                <StudentListTable setModalShow={setShow}/>
            </div>
            <AddstudentModal
                show={show}
                handleClose={handleClose}
            />
        </>

    )
}

export default Student