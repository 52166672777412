import React from 'react'
import { Modal, Button, Form, Row, Col } from 'react-bootstrap';
import { Icon } from '@iconify/react';

function AddstudentModal({ show, handleClose }) {
  return (
    <Modal show={show} onHide={handleClose} size="lg">
    <Modal.Header closeButton>
      <Modal.Title>Add New</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <h6 className="text-md text-primary-light mb-16">Profile Image</h6>
      {/* Upload Image Start */}
      <div className="mb-24 mt-16">
        <div className="avatar-upload">
          <div className="avatar-edit position-absolute bottom-0 end-0 me-24 mt-16 z-1 cursor-pointer">
            <Form.Control type="file" id="imageUpload" accept=".png, .jpg, .jpeg" hidden />
            <label htmlFor="imageUpload" className="w-32-px h-32-px d-flex justify-content-center align-items-center bg-primary-50 text-primary-600 border border-primary-600 bg-hover-primary-100 text-lg rounded-circle">
              <Icon icon="solar:camera-outline"  className="icon" />
            </label>
          </div>
          <div className="avatar-preview">
            <div id="imagePreview"></div>
          </div>
        </div>
      </div>
      {/* Upload Image End */}

      <Form>
        <Row>
          <Col sm="6" className="mb-20">
            <Form.Group controlId="name">
              <Form.Label className="fw-semibold text-primary-light text-sm mb-8">
                Full Name <span className="text-danger-600">*</span>
              </Form.Label>
              <Form.Control type="text" placeholder="Enter Full Name" className="radius-8" />
            </Form.Group>
          </Col>
          <Col sm="6" className="mb-20">
            <Form.Group controlId="email">
              <Form.Label className="fw-semibold text-primary-light text-sm mb-8">
                Email <span className="text-danger-600">*</span>
              </Form.Label>
              <Form.Control type="email" placeholder="Enter email address" className="radius-8" />
            </Form.Group>
          </Col>
          <Col sm="6" className="mb-20">
            <Form.Group controlId="number">
              <Form.Label className="fw-semibold text-primary-light text-sm mb-8">
                Phone
              </Form.Label>
              <Form.Control type="text" placeholder="Enter phone number" className="radius-8" />
            </Form.Group>
          </Col>
          <Col sm="6" className="mb-20">
            <Form.Group controlId="depart">
              <Form.Label className="fw-semibold text-primary-light text-sm mb-8">
                Course <span className="text-danger-600">*</span>
              </Form.Label>
              <Form.Control as="select" className="radius-8">
                <option>Enter Title</option>
                <option>Enter Title One</option>
                <option>Enter Title Two</option>
              </Form.Control>
            </Form.Group>
          </Col>
          <Col sm="6" className="mb-20">
            <Form.Group controlId="desig">
              <Form.Label className="fw-semibold text-primary-light text-sm mb-8">
                Designation <span className="text-danger-600">*</span>
              </Form.Label>
              <Form.Control as="select" className="radius-8">
                <option>Enter Designation Title</option>
                <option>Enter Designation Title One</option>
                <option>Enter Designation Title Two</option>
              </Form.Control>
            </Form.Group>
          </Col>
          <Col sm="6" className="mb-20">
            <Form.Group controlId="Language">
              <Form.Label className="fw-semibold text-primary-light text-sm mb-8">
                Language <span className="text-danger-600">*</span>
              </Form.Label>
              <Form.Control as="select" className="radius-8">
                <option>English</option>
                <option>Bangla</option>
                <option>Hindi</option>
                <option>Arabic</option>
              </Form.Control>
            </Form.Group>
          </Col>
          <Col sm="12" className="mb-20">
            <Form.Group controlId="desc">
              <Form.Label className="fw-semibold text-primary-light text-sm mb-8">
                Description
              </Form.Label>
              <Form.Control as="textarea" placeholder="Write description..." className="radius-8" />
            </Form.Group>
          </Col>
        </Row>

        <div className="d-flex align-items-center justify-content-center gap-3 mt-4">
          <Button variant="outline-danger" onClick={handleClose} className="text-md px-56 py-11 radius-8">
            Cancel
          </Button>
          <Button variant="primary" type="submit" className="text-md px-56 py-12 radius-8">
            Save
          </Button>
        </div>
      </Form>
    </Modal.Body>
  </Modal>
  )
}

export default AddstudentModal