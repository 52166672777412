import { Icon } from '@iconify/react'
import React from 'react'

export default function TopTutors() {
    return (
        <div className="col-xxl-6 col-lg-6">
            <div className="card h-100">
                <div className="card-body">
                    <div className="d-flex align-items-center flex-wrap gap-2 justify-content-between mb-20">
                        <h6 className="mb-2 fw-bold text-lg mb-0">Top Tutors</h6>
                        <a href="javascript:void(0)" className="text-primary-600 hover-text-primary d-flex align-items-center gap-1">
                            View All
                            <Icon icon="solar:alt-arrow-right-linear" className="icon"></Icon>
                        </a>
                    </div>

                    <div className="mt-32">
                        {[
                            { name: 'Dianne Russell', image: 'assets/images/users/user6.png' },
                            { name: 'Wade Warren', image: 'assets/images/users/user1.png' },
                            { name: 'Albert Flores', image: 'assets/images/users/user2.png' },
                            { name: 'Bessie Cooper', image: 'assets/images/users/user3.png' },
                            { name: 'Arlene McCoy', image: 'assets/images/users/user4.png' },
                            { name: 'John Doe', image: 'assets/images/users/user6.png' },
                        ].map((tutor, index) => (
                            <div key={index} className="d-flex align-items-center justify-content-between gap-3 mb-32">
                                <div className="d-flex align-items-center gap-2">
                                    <img src={tutor.image} alt="" className="w-40-px h-40-px radius-8 flex-shrink-0" />
                                    <div className="flex-grow-1">
                                        <h6 className="text-md mb-0 fw-normal">{tutor.name}</h6>
                                        <span className="text-sm text-secondary-light fw-normal">Course Name</span>
                                    </div>
                                </div>
                                <span className="text-primary-light text-md fw-medium">Level: 30</span>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>

    )
}
