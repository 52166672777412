import React from 'react'
import Chart from "react-apexcharts";
export default function StudentStatistics() {

    const options = {
        series: [30, 25],
        colors: ['#18145c', '#32b1d9'],
        labels: ['This Month', 'Last Month'],
        legend: {
            show: false
        },
        chart: {
            type: 'donut',
            height: 230,
            sparkline: {
                enabled: true // Remove whitespace
            },
            margin: {
                top: 0,
                right: 0,
                bottom: 0,
                left: 0
            },
            padding: {
                top: 0,
                right: 0,
                bottom: 0,
                left: 0
            }
        },
        stroke: {
            width: 0,
        },
        dataLabels: {
            enabled: false
        },
        responsive: [{
            breakpoint: 480,
            options: {
                chart: {
                    width: 200
                },
                legend: {
                    position: 'bottom'
                }
            }
        }],
    };

    
    return (
        <div className="col-xxl-3 col-lg-6">
            <div className="card h-100 radius-8 border-0">
                <div className="card-body p-24">
                    <div className="d-flex align-items-center flex-wrap gap-2 justify-content-between">
                        <h6 className="mb-2 fw-bold text-lg">Students Statistics</h6>
                        <div>
                            <select className="form-select form-select-sm w-auto bg-base border text-secondary-light">
                                <option>Yearly</option>
                                <option>Monthly</option>
                                <option>Weekly</option>
                                <option>Today</option>
                            </select>
                        </div>
                    </div>

                    <div className="position-relative">
                        <span className="w-80-px h-80-px bg-base shadow text-primary-light fw-semibold text-xl d-flex justify-content-center align-items-center rounded-circle position-absolute end-0 top-0 z-1">
                            +30%
                        </span>
                        <div id="statisticsDonutChart" className="mt-30 flex-grow-1 apexcharts-tooltip-z-none title-style circle-none">
                            <Chart options={options} series={options.series} type="donut" />
                        </div>
                        <span className="w-80-px h-80-px bg-base shadow text-primary-light fw-semibold text-xl d-flex justify-content-center align-items-center rounded-circle position-absolute start-0 bottom-0 z-1">
                            +25%
                        </span>
                    </div>

                    <ul className="d-flex flex-wrap align-items-center justify-content-between mt-3 gap-3">
                        <li className="d-flex align-items-center gap-2">
                            <span className="w-12-px h-12-px radius-2 bg-primary-600"></span>
                            <span className="text-secondary-light text-sm fw-normal">
                                This Month: <span className="text-primary-light fw-bold">20,000</span>
                            </span>
                        </li>
                        <li className="d-flex align-items-center gap-2">
                            <span className="w-12-px h-12-px radius-2 bg-darkblue"></span>
                            <span className="text-secondary-light text-sm fw-normal">
                                Last Month: <span className="text-primary-light fw-bold">25,000</span>
                            </span>
                        </li>
                    </ul>
                </div>
            </div>
        </div>

    )
}
