import React, { useState } from 'react'
import ListRankedTutors from '../../components/tutors/ListRankedTutors'
import TutorsListTable from '../../components/tutors/TutorsListTable'
import AddtutorModal from '../../components/tutors/AddtutorModal';

function Tutors() {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    return (
        <>
            <div className="dashboard-main-body">
                <ListRankedTutors />
                <TutorsListTable
                    setModalShow={setShow}
                />
            </div>
            <AddtutorModal
                show={show}
                handleClose={handleClose}

            />

        </>

    )
}

export default Tutors