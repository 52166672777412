import React from 'react'
import {Icon} from '@iconify/react'
function AddSubject({setshowAddSubjectModal}) {
    return (
        <div className="card mt-4">
            <div className="card-body">
                <div className="table-responsive">
                    <table className="table table-style">
                        <thead>
                            <tr>
                                <th>
                                    <span className="d-block text-sm fw-normal fs-6">Module Name</span>
                                    <input
                                        type="text"
                                        className="moduleSecInput"
                                        id="floatingInput"
                                        defaultValue="Learn Python"
                                    />
                                </th>
                                <th>
                                    <div className="float-end d-flex justify-content-end">
                                        <div>
                                            <span className="d-inline-block text-sm fw-normal fs-6">Total Time</span>
                                            <div className="icon-field">
                                                <Icon className="icon" icon="carbon:time"></Icon>
                                                <input
                                                    type="text"
                                                    className="moduleSecInput"
                                                    defaultValue="24 Hour"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </th>
                            </tr>
                            <tr>
                                <td colSpan="2">
                                    <button
                                        className="btn btn-outline-primary-600 radius-8 px-20 py-11"
                                        // data-bs-toggle="modal"
                                        // data-bs-target="#staticBackdrop-add-subject"
                                        onClick={()=>setshowAddSubjectModal(true)}
                                    >
                                        Add Subject
                                    </button>
                                </td>
                            </tr>
                        </thead>
                    </table>
                </div>
            </div>
        </div>
    )
}

export default AddSubject