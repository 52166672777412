import React from 'react';
import { Modal, Button, Form } from 'react-bootstrap';

const AddSubjectModal = ({ show, handleClose }) => {
  return (
    <Modal show={show} onHide={handleClose} centered size="lg" backdrop="static" keyboard={false}>
      <Modal.Header closeButton>
        <Modal.Title className="fw-semibold">Add Subject</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form className="add_course_popup">
          <div className="content">
            <div className="user-details">
              <Form.Group className="input-box w-100" controlId="formSubjectName">
                <Form.Label className="details">Subject Name</Form.Label>
                <Form.Control type="text" placeholder="Subject Name" />
              </Form.Group>
              <Form.Group className="input-box w-100" controlId="formDuration">
                <Form.Label className="details">Duration</Form.Label>
                <Form.Control type="text" placeholder="HH:MM:SS" />
              </Form.Group>
              <Form.Group className="input-box w-100" controlId="formURL">
                <Form.Label className="details">URL</Form.Label>
                <Form.Control type="text" placeholder="http://" />
              </Form.Group>
              <Form.Group className="input-box w-100">
                <Form.Label htmlFor="upload_file_course" className="file-upload-label">
                  <svg viewBox="0 0 640 512" height="1em">
                    <path
                      d="M144 480C64.5 480 0 415.5 0 336c0-62.8 40.2-116.2 96.2-135.9c-.1-2.7-.2-5.4-.2-8.1c0-88.4 71.6-160 160-160c59.3 0 111 32.2 138.7 80.2C409.9 102 428.3 96 448 96c53 0 96 43 96 96c0 12.2-2.3 23.8-6.4 34.6C596 238.4 640 290.1 640 352c0 70.7-57.3 128-128 128H144zm79-217c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l39-39V392c0 13.3 10.7 24 24 24s24-10.7 24-24V257.9l39 39c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-80-80c-9.4-9.4-24.6-9.4-33.9 0l-80 80z"
                    />
                  </svg>
                  <p>Drag and Drop</p>
                  <p>or</p>
                  <span className="browse-button btn btn-primary">Browse file</span>
                </Form.Label>
                <Form.Control type="file" className="d-none" id="upload_file_course" />
              </Form.Group>
            </div>
          </div>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button id="row-new-add" variant="primary">
          <span className="d-flex align-items-center">
            <i className="iconify" data-icon="ooui:add"></i>Add
          </span>
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddSubjectModal;
