import React, { useState } from 'react'
import CourseList from '../../components/courses/CourseList'
import CourseListTable from '../../components/courses/CourseListTable'
import AddCourseModal from '../../components/courses/AddCourseModal';

function Course() {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    return (
        <>
            <div className="dashboard-main-body">
                <CourseList />
                <CourseListTable
                    setShowModal={setShow}
                />
            </div>
            <AddCourseModal show={show} handleClose={handleClose}/>
        </>

    )
}

export default Course