import React from 'react'
import {Icon} from'@iconify/react'
function CourseList() {
    return (
        <div className="admin_content_row">
            <h6>Course Preview</h6>
            <div className="users_list_section">
                <div className="users_list_row">
                    <div className="users_list_box">
                        <div className="users_list_box_img"></div>
                        <div className="users_list_box_cnt">
                            <h6>Course Name</h6>
                            <h6>
                                Rating: <Icon icon="material-symbols:star" className='star-icon'></Icon> 4.8
                            </h6>
                        </div>
                    </div>
                    <div className="users_list_box">
                        <div className="users_list_box_img"></div>
                        <div className="users_list_box_cnt">
                            <h6>Course Name</h6>
                            <h6>
                                Rating: <Icon icon="material-symbols:star" className='star-icon'></Icon> 4.8
                            </h6>
                        </div>
                    </div>
                    <div className="users_list_box">
                        <div className="users_list_box_img"></div>
                        <div className="users_list_box_cnt">
                            <h6>Course Name</h6>
                            <h6>
                                Rating: <Icon icon="material-symbols:star" className='star-icon'></Icon> 4.8
                            </h6>
                        </div>
                    </div>
                    <div className="users_list_box">
                        <div className="users_list_box_img"></div>
                        <div className="users_list_box_cnt">
                            <h6>Course Name</h6>
                            <h6>
                                Rating: <Icon icon="material-symbols:star" className='star-icon'></Icon> 4.8
                            </h6>
                        </div>
                    </div>
                    <div className="users_list_box">
                        <div className="users_list_box_img"></div>
                        <div className="users_list_box_cnt">
                            <h6>Course Name</h6>
                            <h6>
                                Rating: <Icon icon="material-symbols:star" className='star-icon'></Icon> 4.8
                            </h6>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default CourseList