import { useAuth } from './context/AuthContext';
import MainLayout from './layout/MainLayout';
import MainDashboard from './components/dashboard/MainDashboard';
import Tutors from './view/tutor/Tutors';
import Student from './view/student/Student';
import { createBrowserRouter, Navigate } from 'react-router-dom';
import AuthLayout from './layout/AuthLayout';
import Course from './view/course/Course';
import Module from './view/course/Module';
import FinancialRecord from './view/financial-record/FinancialRecord';
const AppRoutes = () => {

    // const { isAuthenticated } = useAuth();
    const isAuthenticated = true
    console.log("===isAuthenticated==", isAuthenticated)
    const publicRoutes = [
        {
            path: '/',
            element: <AuthLayout />,
            // children: [
            //   { path: '/', element: <Homepage /> },
            //   { path: 'categories', element: <Categories /> },
            //   { path: 'help', element: <Help /> },
            //   { path: 'contact-us', element: <ContactUs /> },
            //   { path: 'courses', element: <Courses /> },
            //   { path: 'course-details/:id', element: <CourseDetails /> },
            //   { path: 'login', element: <Login /> },
            //   { path: 'register', element: <Register /> },
            //   { path: '*', element: <Navigate to="/" replace /> },
            // ],
        },
    ];

    const privateRoutes = [
        {
            path: '/',
            element: <MainLayout />,
            children: [
                { path: '/', element: <Navigate to="/dashboard" replace /> },
                { path: 'dashboard', element: <MainDashboard /> },
                { path: 'tutors', element: <Tutors /> },
                { path: 'student', element: <Student /> },
                { path: 'course', element: <Course /> },
                { path: 'module', element: <Module /> },
                { path: 'financial-record', element: <FinancialRecord /> },
                //   { path: 'cart', element: <Cart /> },
                //   { path: 'categories', element: <Categories /> },
                //   { path: 'notifications', element: <Notifications /> },
                //   { path: 'help', element: <Help /> },
                //   { path: 'contact-us', element: <ContactUs /> },
                //   { path: 'chat', element: <Chat /> },
                //   { path: 'payment', element: <PaymentGatewayContainer /> },
                { path: '*', element: <Navigate to="/dashboard" replace /> },
            ],
        },
    ];

    return createBrowserRouter(isAuthenticated ? privateRoutes : publicRoutes);
};

export default AppRoutes;
