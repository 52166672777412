import React from 'react'
import { Icon } from '@iconify/react'
function StudentListTable({ setModalShow }) {
  return (
    <div className="card h-100">
    <div className="card-header border-bottom bg-base py-16 px-24 d-flex align-items-center flex-wrap gap-3 justify-content-between">
      <div className="d-flex align-items-center flex-wrap gap-3">
        <form className="navbar-search">
          <input type="text" className="bg-base h-40-px w-auto" name="search" placeholder="Search" />
          <Icon icon="ion:search-outline" className="icon"></Icon>
        </form>
        <select className="form-select form-select-sm w-auto ps-12 py-6 radius-12 h-40-px">
          <option>Status</option>
          <option>Active</option>
          <option>Inactive</option>
        </select>
      </div>
      <a href="#"  className="btn btn-primary text-sm btn-sm px-12 py-12 radius-8 d-flex align-items-center gap-2" onClick={() => setModalShow(true)}>
        <Icon icon="ic:baseline-plus" className="icon text-xl line-height-1"></Icon>
        Add New Student
      </a>
    </div>
    <div className="card-body">
      <div className="table-responsive">
        <table className="table table-style mb-0">
          <thead>
            <tr>
              <th>#</th>
              <th>Name</th>
              <th>Rank</th>
              <th>Course</th>
              <th>Date</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>01</td>
              <td>Kathryn Murphy</td>
              <td>25</td>
              <td>Course</td>
              <td>25 Jan 2024</td>
              <td>
                <div className="d-flex align-items-center gap-10">
                  <button type="button" className="card-edit-button text-success-600">
                    <Icon icon="lucide:edit" className="icon text-lg line-height-1"></Icon>
                  </button>
                  <button type="button" className="card-delete-button text-danger-600">
                    <Icon icon="fluent:delete-24-regular" className="icon text-lg line-height-1"></Icon>
                  </button>
                </div>
              </td>
            </tr>
            <tr>
              <td>01</td>
              <td>Kathryn Murphy</td>
              <td>25</td>
              <td>Course</td>
              <td>25 Jan 2024</td>
              <td>
                <div className="d-flex align-items-center gap-10">
                  <button type="button" className="card-edit-button text-success-600">
                    <Icon icon="lucide:edit" className="icon text-lg line-height-1"></Icon>
                  </button>
                  <button type="button" className="card-delete-button text-danger-600">
                    <Icon icon="fluent:delete-24-regular" className="icon text-lg line-height-1"></Icon>
                  </button>
                </div>
              </td>
            </tr>
            <tr>
              <td>01</td>
              <td>Kathryn Murphy</td>
              <td>25</td>
              <td>Course</td>
              <td>25 Jan 2024</td>
              <td>
                <div className="d-flex align-items-center gap-10">
                  <button type="button" className="card-edit-button text-success-600">
                    <Icon icon="lucide:edit" className="icon text-lg line-height-1"></Icon>
                  </button>
                  <button type="button" className="card-delete-button text-danger-600">
                    <Icon icon="fluent:delete-24-regular" className="icon text-lg line-height-1"></Icon>
                  </button>
                </div>
              </td>
            </tr>
            <tr>
              <td>01</td>
              <td>Kathryn Murphy</td>
              <td>25</td>
              <td>Course</td>
              <td>25 Jan 2024</td>
              <td>
                <div className="d-flex align-items-center gap-10">
                  <button type="button" className="card-edit-button text-success-600">
                    <Icon icon="lucide:edit" className="icon text-lg line-height-1"></Icon>
                  </button>
                  <button type="button" className="card-delete-button text-danger-600">
                    <Icon icon="fluent:delete-24-regular" className="icon text-lg line-height-1"></Icon>
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="d-flex align-items-center justify-content-between flex-wrap gap-2 mt-24">
        <span>Showing 1 to 10 of 12 entries</span>
        <ul className="pagination d-flex flex-wrap align-items-center gap-2 justify-content-center">
          <li className="page-item">
            <a className="page-link bg-neutral-100 text-secondary-light fw-semibold radius-8 border-0 d-flex align-items-center justify-content-center h-32-px w-32-px text-md" href="javascript:void(0)">
              <Icon icon="ep:d-arrow-left"></Icon>
            </a>
          </li>
          <li className="page-item">
            <a className="page-link text-secondary-light fw-semibold radius-8 border-0 d-flex align-items-center justify-content-center h-32-px w-32-px text-md bg-primary-600 text-white" href="javascript:void(0)">1</a>
          </li>
          <li className="page-item">
            <a className="page-link bg-neutral-100 text-secondary-light fw-semibold radius-8 border-0 d-flex align-items-center justify-content-center h-32-px w-32-px" href="javascript:void(0)">2</a>
          </li>
          <li className="page-item">
            <a className="page-link bg-neutral-100 text-secondary-light fw-semibold radius-8 border-0 d-flex align-items-center justify-content-center h-32-px w-32-px text-md" href="javascript:void(0)">3</a>
          </li>
          <li className="page-item">
            <a className="page-link bg-neutral-100 text-secondary-light fw-semibold radius-8 border-0 d-flex align-items-center justify-content-center h-32-px w-32-px text-md" href="javascript:void(0)">4</a>
          </li>
          <li className="page-item">
            <a className="page-link bg-neutral-100 text-secondary-light fw-semibold radius-8 border-0 d-flex align-items-center justify-content-center h-32-px w-32-px text-md" href="javascript:void(0)">5</a>
          </li>
          <li className="page-item">
            <a className="page-link bg-neutral-100 text-secondary-light fw-semibold radius-8 border-0 d-flex align-items-center justify-content-center h-32-px w-32-px text-md" href="javascript:void(0)">
              <Icon icon="ep:d-arrow-right"></Icon>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
  
  )
}

export default StudentListTable